export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは4月より%d点上がりました。この調子です！<br>\n6月の特集は<b>❝睡眠・休息の質を上げよう❞</b>です。\r\n6月は気温や湿度の上昇で寝苦しくなることから、1年のうちで1番睡眠時間が短い月と言われています。エアコンや除湿器を活用して、快適な睡眠環境を整えていきましょう。\r\n\n",
      "down": "生活習慣スコアは4月より%d点下がりました。どの項目に変化がありましたか？<br>\n6月の特集は<b>❝睡眠・休息の質を上げよう❞</b>です。\r\n6月は気温や湿度の上昇で寝苦しくなることから、1年のうちで1番睡眠時間が短い月と言われています。エアコンや除湿器を活用して、快適な睡眠環境を整えていきましょう。\r\n\n",
      "equal100": "生活習慣スコアは4月に引き続き%満点%です。<br>\n6月の特集は<b>❝睡眠・休息の質を上げよう❞</b>です。\r\n6月は気温や湿度の上昇で寝苦しくなることから、1年のうちで1番睡眠時間が短い月と言われています。エアコンや除湿器を活用して、快適な睡眠環境を整えていきましょう。\r\n\n",
      "equal": "生活習慣スコアは4月と同じ点数です。<br>\n6月の特集は<b>❝睡眠・休息の質を上げよう❞</b>です。\r\n6月は気温や湿度の上昇で寝苦しくなることから、1年のうちで1番睡眠時間が短い月と言われています。エアコンや除湿器を活用して、快適な睡眠環境を整えていきましょう。\r\n\n"
    },
    "steps": {
      "up": "歩数スコアは4月より%d点上がりました。<br>\n<b>6月25日(火)からWalk個人チャレンジが始まります！</b>引き続きたくさん歩いて素敵な商品をゲットしましょう！\n\n",
      "down": "歩数スコアは4月より%d点下がりました。<br>\n<b>6月25日(火)からWalk個人チャレンジが始まります！</b>たくさん歩いて素敵な商品をゲットしましょう！\n\n",
      "equal": "歩数スコアは4月と同じ点数です。<br>\n<b>6月25日(火)からWalk個人チャレンジが始まります！</b>たくさん歩いて素敵な商品をゲットしましょう！\n\n"
    },
    "exercise": {
      "up": "運動のスコアは4月より%d点上がりました。<br>\n外で運動が出来ない！そんな日は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-breakのアーカイブ動画</a>を見ながら、室内で体を動かしてみませんか？\n\n",
      "down": "運動のスコアは4月より%d点下がりました。<br>\n外で運動が出来ない！そんな日は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-breakのアーカイブ動画</a>を見ながら、室内で体を動かしてみませんか？\n\n",
      "equal": "運動のスコアは4月と同じ点数です。<br>\n外で運動が出来ない！そんな日は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-breakのアーカイブ動画</a>を見ながら、室内で体を動かしてみませんか？\n\n"
    },
    "meal": {
      "up": "食事のスコアは4月より%d点上がりました。<br>\nよく噛んで食べることにはダイエット効果があります！<b>「1口あたり30回」</b>を目安に噛むことを意識しましょう。詳細は<a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">こちら</a>から。\n\n",
      "down": "食事のスコアは4月より%d点下がりました。<br>\nよく噛んで食べることにはダイエット効果があります！<b>「1口あたり30回」</b>を目安に噛むことを意識しましょう。詳細は<a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">こちら</a>から。\n\n",
      "equal": "食事のスコアは4月と同じ点数です。<br>\nよく噛んで食べることにはダイエット効果があります！<b>「1口あたり30回」</b>を目安に噛むことを意識しましょう。詳細は<a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">こちら</a>から。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは4月より%d点上がりました。素晴らしいです！<br>\nアルコールには強い利尿作用があります。二日酔い防止にもつながるので、飲酒の際は水分摂取もお忘れなく！\n\n",
      "down": "飲酒のスコアは4月より%d点下がりました。<br>\nアルコールには強い利尿作用があります。二日酔い防止にもつながるので、飲酒の際は水分摂取もお忘れなく！\n\n",
      "equal": "飲酒のスコアは4月と同じ点数です。<br>\nアルコールには強い利尿作用があります。二日酔い防止にもつながるので、飲酒の際は水分摂取もお忘れなく！\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは4月より%d点上がりました。<br>\n体をより元気な状態へ整えるために、<a href=\"https://go.andwell.jp/library/8f902a02-32c5-11ee-9fb8-0e78a41e91b5\">こちら</a>を読んで❝いい睡眠❞について考えてみませんか？\n\n",
      "down": "睡眠のスコアは4月より%d点下がりました。<br>\n体をより元気な状態へ整えるために、<a href=\"https://go.andwell.jp/library/8f902a02-32c5-11ee-9fb8-0e78a41e91b5\">こちら</a>を読んで❝いい睡眠❞について考えてみませんか？\n\n",
      "equal": "睡眠のスコアは4月と同じ点数です。<br>\n体をより元気な状態へ整えるために、<a href=\"https://go.andwell.jp/library/8f902a02-32c5-11ee-9fb8-0e78a41e91b5\">こちら</a>を読んで❝いい睡眠❞について考えてみませんか？\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは4月より%d点上がりました。<br>\nあなたのストレス解消法は何ですか？<a href=\"https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062\">こちら</a>を参考にしながらストレスとうまく付き合う方法を知りましょう！\n\n",
      "down": "ストレスのスコアは4月より%d点下がりました。<br>\nあなたのストレス解消法は何ですか？<a href=\"https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062\">こちら</a>を参考にしながらストレスとうまく付き合う方法を知りましょう！\n\n",
      "equal": "ストレスのスコアは4月と同じ点数です。<br>\nあなたのストレス解消法は何ですか？<a href=\"https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062\">こちら</a>を参考にしながらストレスとうまく付き合う方法を知りましょう！\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up by %d points since April. Keep it up!<br>\nJune's featured topic is <b>\"Improve the Quality of Sleep and Rest.\"</b>\r\nJune is said to be the shortest month of the year for sleeping, as the rising temperature and humidity make it difficult to sleep. Let's create a comfortable sleeping environment by utilizing air conditioners and dehumidifiers.\n\n",
      "down": "Your lifestyle score has gone down by %d points since April. What has changed?<br>\nJune's featured topic is <b>\"Improve the Quality of Sleep and Rest.\"</b>\r\nJune is said to be the shortest month of the year for sleeping, as the rising temperature and humidity make it difficult to sleep. Let's create a comfortable sleeping environment by utilizing air conditioners and dehumidifiers.\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in April. <br>\nJune's featured topic is <b>\"Improve the Quality of Sleep and Rest.\"</b>\r\nJune is said to be the shortest month of the year for sleeping, as the rising temperature and humidity make it difficult to sleep. Let's create a comfortable sleeping environment by utilizing air conditioners and dehumidifiers.\n\n",
      "equal": "Your lifestyle score is the same as it was in April. <br>\nJune's featured topic is <b>\"Improve the Quality of Sleep and Rest.\"</b>\r\nJune is said to be the shortest month of the year for sleeping, as the rising temperature and humidity make it difficult to sleep. Let's create a comfortable sleeping environment by utilizing air conditioners and dehumidifiers.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since April.<br>\n<b>Individual Walk Challenge will begin on Tuesday, June 25!</b> Let's continue to walk a lot and earn great products!\n\n",
      "down": "Your steps score has gone down %d points since April. <br>\n<b>Individual Walk Challenge will begin on Tuesday, June 25!</b> Let's continue to walk a lot and earn great products!\n\n",
      "equal": "Your steps score is the same as it was in April. <br>\n<b>Individual Walk Challenge will begin on Tuesday, June 25!</b> Let's continue to walk a lot and earn great products!\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since April.<br>\nI can't exercise outside! On such a day, why don't you watch <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">the archived videos of 10minutes-break</a> and move your body indoors?\n\n",
      "down": "Your exercise score has gone down %d points since April.<br>\nI can't exercise outside! On such a day, why don't you watch <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">the archived videos of 10minutes-break</a> and move your body indoors?\n\n",
      "equal": "Your exercise score is the same as it was in April. <br>\nI can't exercise outside! On such a day, why don't you watch <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">the archived videos of 10minutes-break</a> and move your body indoors?\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since April.<br>\nChewing your food well has a weight loss effect! Chew your food <b>\"30 times per mouthful.\"</b> <a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">Click here</a> for details\n\n",
      "down": "Your food score has gone down %d points since April.<br>\nChewing your food well has a weight loss effect! Chew your food <b>\"30 times per mouthful.\"</b><a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">Click here</a> for details\n\n",
      "equal": "Your food score is the same as it was in April. <br>\nChewing your food well has a weight loss effect! Chew your food <b>\"30 times per mouthful.\"</b><a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">Click here</a> for details\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since April.That's excellent!<br>\nAlcohol has a strong diuretic effect. Don't forget to drink water when consuming alcohol, as it will help prevent hangovers!\n\n",
      "down": "Your alcohol score has gone down %d points since April.<br>\nAlcohol has a strong diuretic effect. Don't forget to drink water when consuming alcohol, as it will help prevent hangovers!\n\n",
      "equal": "Your alcohol score is the same as it was in April. <br>\nAlcohol has a strong diuretic effect. Don't forget to drink water when consuming alcohol, as it will help prevent hangovers!\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since April.<br>\nTo help your body get into a more energetic state, why don't you read <a href=\"https://go.andwell.jp/library/8f902a02-32c5-11ee-9fb8-0e78a41e91b5\">this article</a> and think about \"good sleep\"?\n\n",
      "down": "Your sleep score has gone down %d points since April.<br>\nTo help your body get into a more energetic state, why don't you read <a href=\"https://go.andwell.jp/library/8f902a02-32c5-11ee-9fb8-0e78a41e91b5\">this article</a> and think about \"good sleep\"?\n\n",
      "equal": "Your sleep score is the same as it was in April. <br>\nTo help your body get into a more energetic state, why don't you read <a href=\"https://go.andwell.jp/library/8f902a02-32c5-11ee-9fb8-0e78a41e91b5\">this article</a> and think about \"good sleep\"?\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since April.<br>\nWhat is your stress relief method? Let's learn how to effectively deal with stress by referring to <a href=\"https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062\">this page</a>!\n\n",
      "down": "Your stress score has gone down %d points since April.<br>\nWhat is your stress relief method? Let's learn how to effectively deal with stress by referring to <a href=\"https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062\">this page</a>!\n\n",
      "equal": "Your stress score is the same as it was in April. <br>\nWhat is your stress relief method? Let's learn how to effectively deal with stress by referring to <a href=\"https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062\">this page</a>!\n\n"
    }
  }
}